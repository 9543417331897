import React from "react"
import { DefaultLayout } from "layouts"
import { News } from "containers"
import { Lists } from "components"

import bgHeader from "../../images/bg-header.png"
import articleThumbnail01 from "../../images/article-thumbnail-01.png"
import articleThumbnail02 from "../../images/article-thumbnail-02.png"
import articleThumbnail03 from "../../images/article-thumbnail-03.png"

const articlesListPaginationProps: IArticlesListPagination = {
  headline: "Latest news",
  description:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita",
  articles: [
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
  ],
}

const boxWrapperProps = {
  heroProps: {
    background: bgHeader,
    headline: "B&F News",
  },
  boxContent: <Lists.Articles {...articlesListPaginationProps} />,
  breadcrumbProps: {
    paths: [
      {
        path: "/",
        label: "Home",
      },
      {
        path: "/news",
        label: "B&F News",
      },
    ],
  },
}

const newsProps: INewsContainerProps = {
  boxWrapperProps,
}

const Index = (props: any) => {
  return (
    <DefaultLayout {...props}>
      <News {...newsProps} />
    </DefaultLayout>
  )
}

export default Index
